var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../../components/settings';
import { getOneLevel } from '../../../redux/slices/levels/actions';
import { dispatch, useSelector } from '../../../redux/store';
import LevelForm from '../../../sections/@dashboard/Configuration/Levels/LevelForm';
// ----------------------------------------------------------------------
export default function LevelsEdit() {
    var themeStretch = useSettingsContext().themeStretch;
    var id = useParams().id;
    useEffect(function () {
        if (id) {
            dispatch(getOneLevel(id));
        }
    }, [id]);
    var level = useSelector(function (state) { return state.levels; }).level;
    return (_jsxs(_Fragment, { children: [_jsx(Helmet, { children: _jsx("title", { children: " Level: Edit " }) }), _jsxs(Container, __assign({ maxWidth: themeStretch ? false : 'lg' }, { children: [_jsx(CustomBreadcrumbs, { heading: "Edit Level", links: [
                            {
                                name: 'Level',
                                href: PATH_DASHBOARD.configuration.levels.root,
                            },
                            { name: 'Edit Level' },
                        ] }), _jsx(LevelForm, { isEdit: true, currentLevel: level || undefined }, new Date().toISOString())] }))] }));
}
